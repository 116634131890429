<template>
  <section id="main" class="main-viewport">
    <div class="container-fluid content-wrap p-0">
      <div class="row no-gutters flex-no-wrap">
        <div class="d-lg-flex col-auto sbar-w">
          <Sidebar :slug="slug" :type="type"/>
        </div>
        <div class="col-12 col-lg-auto flex-grow-1">
          <div class="content-wrap-inner flex-wrap d-flex flex-column justify-content-between">
            <div :class="`vbry-playlist vinyl-grp-wrap ${isPlaying ? 'playing' : 'playing animate-paused'}`" id="vinyl_record">
              <div class="playlist-wrap">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="album-cover">
                      <div class="title-label">
                        <span>{{songType || 'DISCOVER'}}</span>
                      </div>
                      <div class="title">
                        <h1>{{categoryName || 'Hot Picks'}}</h1>
                      </div>
                      <div class="meta">
                        <a :class="`play-all ${(songs && songs.length > 0) ? '' : 'disabled'}`" id="play_all" @click.prevent="playAll">
                          {{isPlaying ? 'Pause' : 'Play All'}} <ion-icon :name="`${isPlaying ? 'pause-sharp' : 'play-sharp'}`"></ion-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="vinyl-crop">
                      <div class="vinyl-grp">
                        <div class="vinyl-img">
                          <img
                              src="/images/vinyl-disk.png"
                              width="320"
                              height="320"
                              alt="album cover"
                              loading="lazy"
                              draggable="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="play-table">
                  <div class="heading d-flex">
                    <div class="title-col px-1">
                      <span>TITLE</span>
                    </div>
                    <div class="duration-col px-1">
                      <span>DURATION</span>
                    </div>
                    <div v-if="!isSoundEffect" class="tempo-col px-1">
                      <span>TEMPO</span>
                    </div>
                  </div>
                  <div class="queue">
                    <div v-for="(song, index) in songs" :key="index" class="song-row d-flex" :id="song.slug">
                      <SongWaveSurfer :song="song" :index="index" :selectedSong="selectedSong" :addSong="addSong" :addSoundEffects="addSoundEffects" />
                    </div>

                    <!-- loader-->
                    <div v-if="loadMore" class="itm-loader">
                      <div class="song-row d-flex">
                        <div class="play-col px-1 my-auto">
                          <div class="mx-lg-2">
                            <div class="skl-loader-itm"></div>
                          </div>
                        </div>
                        <div class="title-col px-1 my-auto">
                          <div class="skl-loader-itm" style="width:250px; height:21px; animation: loads 30s infinite linear;"></div>
                        </div>
                        <div class="duration-col px-1 my-auto">
                          <div class="skl-loader-itm"></div>
                        </div>
                        <div class="tempo-col px-1 my-auto">
                          <div class="skl-loader-itm"></div>
                        </div>
                        <div class="preview-col flex-grow-1 my-auto mx-lg-4">
                          <div class="skl-loader-itm" style="width: 100%; height:40px; animation: loads 10s infinite linear;"></div>
                        </div>
                        <div class="download-col my-auto mx-lg-2">
                          <div class="skl-loader-itm"></div>
                        </div>
                      </div>
                      <div class="song-row d-flex">
                        <div class="play-col px-1 my-auto">
                          <div class="mx-lg-2">
                            <div class="skl-loader-itm"></div>
                          </div>
                        </div>
                        <div class="title-col px-1 my-auto">
                          <div class="skl-loader-itm" style="width:250px; height:21px; animation: loads 30s infinite linear;"></div>
                        </div>
                        <div class="duration-col px-1 my-auto">
                          <div class="skl-loader-itm"></div>
                        </div>
                        <div class="tempo-col px-1 my-auto">
                          <div class="skl-loader-itm"></div>
                        </div>
                        <div class="preview-col flex-grow-1 my-auto mx-lg-4">
                          <div class="skl-loader-itm" style="width: 100%; height:40px; animation: loads 10s infinite linear;"></div>
                        </div>
                        <div class="download-col my-auto mx-lg-2">
                          <div class="skl-loader-itm"></div>
                        </div>
                      </div>
                    </div>
                    <!--end loadaer-->

                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@keyframes loads{
  0%{
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}
.itm-loader{
  position: relative;
  padding: 16px 0px;
}
.skl-loader-itm{
  background-color: rgba(0,0,0,0.05);
  background: linear-gradient(to right, rgba(0,0,0,0.05) 0%, rgba(255,255,255,1) 30%, rgba(0,0,0,0.05) 60%);
  animation: loads 60s infinite linear;
  max-width: 100%;
}
.itm-loader .play-col .skl-loader-itm,
.itm-loader .download-col .skl-loader-itm{
  width:52px; height:52px; border-radius: 100%; animation: loads 60s infinite linear;
}
.itm-loader .tempo-col .skl-loader-itm,
.itm-loader .duration-col .skl-loader-itm{
  width: 70px; height:21px;
}
.itm-loader .song-row{
  padding-bottom: 16px;
}
@media (max-width: 1460px) and (min-width: 992px){
  .itm-loader .play-col .skl-loader-itm,
  .itm-loader .download-col .skl-loader-itm{
    width: 36px;
    height: 36px;
  }
}
@media (max-width: 991px){
  .itm-loader .duration-col:before,
  .itm-loader .tempo-col:before{
    display: none;
  }
  .itm-loader .download-col .skl-loader-itm{
    width: 100%;
    border-radius: 0;
  }
  .itm-loader .tempo-col .skl-loader-itm{
    margin-left: auto;
  }
  .itm-loader .tempo-col .skl-loader-itm,
  .itm-loader .duration-col .skl-loader-itm{
    width: 120px; height:21px;
  }
  .itm-loader .song-row{
    padding-bottom: 24px;
  }
}
</style>

<script>
import Sidebar from "../partials/Sidebar.vue";
import Footer from "../partials/Footer.vue";
import constant from "../../constants/constant";
import { DownloadIcon, ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';
import { mapGetters, mapActions } from 'vuex';
import SongWaveSurfer from '../pages/SongWaveSurfer.vue';

export default {
  props: ['type', 'slug'],
  components: {
    Footer,
    Sidebar,
    DownloadIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    SongWaveSurfer
  },
  data() {
    return {
      playlistSongLabel: 'Song',
      MAIN_URL: '',
      newSongTitle: '',
      newSongShortTrack: '',
      loadMore: false,
      paginationNumber: 1,
      isSoundEffect: false,
      isLastPage:false
    };
  },
  async mounted() {
    console.log('mounted home');
    window.addEventListener('scroll', this.handleScroll);
    this.MAIN_URL = constant.MAIN_URL;
    this.$store.commit("player/SET_LOADED_SONGS", 0);
    if (!this.slug) {
      await this.getSongs();
    }
    this.$store.commit("player/SET_IS_LOADING", false);
    if (this.$route.name === 'HomeSearch' || this.$route.name === 'HomeMusic') {
      let link = document.getElementsByClassName('scroll');
      setTimeout(() => {
        link[0].click();
      }, 2000);
    }
    if (location.pathname == '/soundeffects') {
      this.isSoundEffect = true;
    }
  },
  methods: {
    ...mapActions({
      fetchSongs: 'player/fetchSongs',
      fetchAddedSongs: 'player/fetchAddedSongs',
    }),
    // async addSong() {
    //   console.log('addSong');
    //   this.paginationNumber += 1;
    //   var url = `${this.MAIN_URL}/api/playlist?page=${this.paginationNumber}`;
    //   this.loadMore = true;
    //   this.$store.commit("player/SET_LOAD_MORE", this.loadMore);
    //   await this.fetchAddedSongs({ url });
    //   this.loadMore = false;
    //   this.$store.commit("player/SET_LOAD_MORE", this.loadMore);
    // },

    async addSong() {
      let pageNumber = this.$route.query.page || null;
      this.paginationNumber = this.paginationNumber + 1;
      console.log('new songs before', this.added_songs);

      var self = this;
      let url = this.pagination.next_page_url;
      // Check if the current route contains a genre
      // if (this.$route.path.includes('/genre/')) {
      //   // Extract genre from the path
      //   let genre = this.$route.path.split('/genre/')[1].split('/')[0];
      //   // Construct URL for genre-based pagination
      //   url = `${this.MAIN_URL}/genre/${genre}?page=${this.paginationNumber}`;
      // } else {
      //   // Default playlist pagination URL
      //   url = `${this.MAIN_URL}/api/playlist?page=${this.paginationNumber}`;
      // }
      if(url){
        this.loadMore = true;
        this.$store.commit("player/SET_LOAD_MORE", this.loadMore);
        await self.fetchAddedSongs({url:url});

        setTimeout(() => {

          this.added_songs.forEach(song => {
            self.songs.push(song);
          });
          if(this.pagination.last_page_url == this.pagination.next_page_url){
            this.isLastPage = true;
          }else{
            this.isLastPage = false;
          }

          self.loadMore = false;
          self.$store.commit("player/SET_LOAD_MORE", self.loadMore);
        }, 1500);

        console.log('new songs after', this.added_songs);
      }

    },

    async addSoundEffects() {
      console.log('addSoundEffects');
      this.paginationNumber += 1;
      var url = `${this.MAIN_URL}/api/soundeffects_playlist?page=${this.paginationNumber}`;
      this.loadMore = true;
      this.$store.commit("player/SET_LOAD_MORE", this.loadMore);
      await this.fetchAddedSongs({ url });
      this.loadMore = false;
      this.$store.commit("player/SET_LOAD_MORE", this.loadMore);
    },
    async getSongsOfPage(url) {
      console.log('getSongsOfPage', url);
      if (this.loadedSongs !== (this.songs ? this.songs.length : 0) || this.loadedSongs === 0) {
        this.$store.commit("player/SET_LOADED_SONGS", 0);
        if (this.selectedSong && this.selectedSong.wavesurfer) {
          this.selectedSong.wavesurfer.stop();
          this.selectedSong.wavesurfer.destroyAllPlugins();
        }
        this.$store.commit("player/SET_IS_PLAYING", false);
        this.$store.commit("player/SET_SHOW_STICKY_PLAYER", false);
        // this.$store.commit("player/SET_SELECTED_SONG", null);
        await this.fetchSongs({ url });
      }
    },
    async getSongs() {
      console.log('getSongs');
      let result = await this.fetchSongs({ url: `${this.MAIN_URL}/api/playlist` });
      if (result.status && this.songs.length > 1) {
        this.playlistSongLabel = 'Songs';
      }
    },
    selectSong(song) {
      console.log('selectSong', song);
      this.$store.commit("player/SET_SELECTED_SONG", song);
      this.playSong(song);
    },
    playSong(song) {
      console.log('playSong', song);
      // Implement play song logic
    },
    playAll() {
      console.log('playAll');
      if (this.currentWavesurfer) {
        this.currentWavesurfer.playPause();
        this.$store.commit("player/SET_IS_PLAYING", this.currentWavesurfer.isPlaying());
        this.$store.commit("player/SET_SHOW_STICKY_PLAYER", this.currentWavesurfer.isPlaying());
      } else {
        document.getElementById(this.songs[0].id).click();
        this.$store.commit("player/SET_IS_PLAYING", true);
      }
    },
    downloadSong(song) {
      console.log('downloadSong', song);
      if (song) {
        if (song.wavesurfer && song.wavesurfer.isPlaying()) {
          song.wavesurfer.pause();
        }
        this.$router.push({ path: `/download/${song.slug}` });
      }
    },
    async handleScroll() {
      // console.log('handleScroll');
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= documentHeight - 250) {
        await this.triggerBottomEvent();
      }
    },
    async triggerBottomEvent() {
      console.log('triggerBottomEvent');
      if (!this.loadMore) {
        if (location.pathname == '/soundeffects') {
          await this.addSoundEffects();
        } else {
          await this.addSong();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      songs: 'player/getSongs',
      selectedSong: 'player/getSelectedSong',
      currentVolume: 'player/getVolume',
      isPlaying: 'player/getIsPlaying',
      pagination: 'player/getPagination',
      loadedSongs: 'player/getLoadedSongs',
      categoryName: 'player/getCategoryName',
      songType: 'player/getType',
      added_songs: 'player/getAddedSongs',
      currentWavesurfer: 'player/getCurrentWaveSurfer',
    }),
  }
};
</script>
